angular.module('fingerink')
    .service('ckEditorModal', function ($uibModal) {
        this.modalInstance = null;
        this.openModal = function () {
            this.modalInstance = $uibModal.open({
                animation: false,
                backdrop: 'static',
                size: 'lg',
                windowClass: 'signaturemodal',
                controller: 'ckEditorModalCtrl',
                controllerAs: 'controller',
                templateUrl: 'modals/ckEditorModal.tpl.html'
            });

            return this.modalInstance;
        };

    })
    .controller('ckEditorModalCtrl', function modalCkeditorCtrl($rootScope, $scope, $uibModalInstance, $window, $timeout) {
        var that = this;
        that.close =  ()=>$uibModalInstance.close();
        that.ok = () =>$uibModalInstance.close(window.editor.getData());
    });
